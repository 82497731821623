!function(o,c) {
	var n=c.documentElement,t=" w-mod-";
	n.className+=t+"js",("ontouchstart"in o||o.DocumentTouch&&c instanceof DocumentTouch)&&(n.className+=t+"touch")
}(window,document);

jQuery(document).ready(function($) {
	// Mobile menu
	var $mobileMenuTrigger = $(".menu-button");
	var $mobileMenuCloseBtn = $(".menu-button-close");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		},
		offCanvas: true
	});

	$mobileMenuTrigger.click(function() {
		$( "html" ).addClass( "open-mobile-menu" );
		$mobileMenu.show("slide", { direction: "left" }, 800);
		$mobileMenuTrigger.hide();
		$mobileMenuCloseBtn.show();
	});

	$mobileMenuCloseBtn.click(function() {
		$( "html" ).removeClass( "open-mobile-menu" );
		$mobileMenu.hide("slide", { direction: "left" }, 800);
		$mobileMenuTrigger.show();
		$mobileMenuCloseBtn.hide();
	});

	$(".search-link-icon").click(function() {
		if ($('.search-column').is(':visible')) {
			$mobileMenu.removeClass( "extra-space" );
		}
		else {
			$mobileMenu.addClass( "extra-space" );
		}
	});

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 767 ) {
		$(".inside-content-section").fitVids({ ignore: ".nofit" });
	}

	// fixed header
	fixedHeader();

	$(window).scroll(function() {
		fixedHeader();
	});

	function fixedHeader(onLoad) {
		if ($(this).scrollTop() > 32) {
			$('.header-section').addClass("fixed-header");
		}
		else {
			$('.header-section').removeClass("fixed-header");
		};
	}

	// dropdowns
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".dropdown-wrapper-custom > .link-dropdown-custom").click(function(event) {
			if ( !$(this).parents(".dropdown-wrapper-custom").hasClass("open") ) {
				$(".dropdown-wrapper-custom").removeClass("open");
				event.preventDefault();
				$(this).parent(".dropdown-wrapper-custom").addClass("open");
			}
		});
	}
	else {
		$(".dropdown-wrapper-custom").hover(function() {
			var $this = $(this),
				timer = $this.data("timer") || 0;

			clearTimeout(timer);

			timer = setTimeout(function() {
				$this.addClass("open");
			}, 200);

			$this.data("timer", timer);
		}, function() {
			var $this = $(this),
				timer = $this.data("timer") || 0;

			clearTimeout(timer);

			$this.removeClass("open");
		});
	}

	// toggle search facets
	$("#collapse-facets").click(function() {
		$("#search-facets-form").slideToggle();
		$(this).toggleClass("expanded");
	});

	// toggle search facets groups
	$(".grouped-options-header").click(function() {
		$(this).next(".grouped-options").slideToggle();
		$(this).next(".grouped-options").toggleClass("expanded");
		$(this).toggleClass("expanded");
	});

	// Update products page styles
	if ($(".main-content-container #products_module.responsive.cms_entity").length) {
		$(".inside-page-header-content-wrap").css({ "border-bottom": "0px", "padding-bottom": "0px" });
	}

	// Remove border from link header images
	$('#products_module.responsive .category-heading img').parent('a').css('border-bottom', '0px');

	// Remove border from all link images
	$('a img').parent('a').css('border-bottom', '0px');

	/*** ----------------------------------------------------------------------
	* Webflow: Interactions: Init
	*/
	/* eslint-disable */
	Webflow.require('ix').init([
		{"slug":"dropdown-hover","name":"Dropdown Hover","value":{"style":{},"triggers":[{"type":"hover","selector":".dropdown-list","descend":true,"stepsA":[{"wait":"100ms"},{"display":"block","opacity":1,"height":"auto","transition":"opacity 300ms ease 0ms, height 300ms ease 0ms"}],"stepsB":[{"wait":"100ms"},{"display":"none","opacity":0,"height":"0px","transition":"opacity 300ms ease 0ms, height 300ms ease 0ms"}]}]}},
		{"slug":"flyout-hover","name":"Flyout Hover","value":{"style":{},"triggers":[]}},
		{"slug":"minislideshow-load","name":"MiniSlideshow Load","value":{"style":{"opacity":0,"x":"0px","y":"200px","z":"0px"},"triggers":[{"type":"load","stepsA":[{"wait":"450ms"},{"opacity":1,"transition":"transform 1000ms ease-in-out 0, opacity 1000ms ease-in-out 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
		{"slug":"show-hidden-search-input","name":"Show Hidden Search Input","value":{"style":{},"triggers":[{"type":"click","selector":".search-input-hidden","stepsA":[{"wait":"450ms","display":"block","opacity":1,"transition":"opacity 450ms ease 0"}],"stepsB":[{"display":"none","opacity":0,"transition":"opacity 450ms ease 0"}]}]}},
		{"slug":"show-present-text","name":"Show Present Text","value":{"style":{},"triggers":[{"type":"hover","selector":".present-text-wrapper","stepsA":[{"wait":"450ms","display":"flex","opacity":1,"transition":"opacity 450ms ease 0"}],"stepsB":[]}]}},
		{"slug":"intro-load","name":"Intro Load","value":{"style":{"opacity":0,"x":"0px","y":"200px","z":"0px"},"triggers":[{"type":"load","stepsA":[{"wait":"450ms"},{"opacity":1,"transition":"transform 1250ms ease 0, opacity 1500ms ease 0","x":"0px","y":"0px","z":"0px"}],"stepsB":[]}]}},
		{"slug":"show-icon-title","name":"Show Icon Title","value":{"style":{},"triggers":[{"type":"hover","selector":".sign-in-title","stepsA":[{"wait":"450ms","display":"block","opacity":1,"transition":"opacity 450ms ease 0"}],"stepsB":[{"wait":"350ms","display":"block","opacity":0,"transition":"opacity 350ms ease 0"}]}]}},
		{"slug":"show-icon-title-2","name":"Show Icon Title 2","value":{"style":{},"triggers":[{"type":"hover","selector":".wishlist-title","stepsA":[{"wait":"450ms","display":"block","opacity":1,"transition":"opacity 450ms ease 0"}],"stepsB":[{"wait":"350ms","display":"block","opacity":0,"transition":"opacity 350ms ease 0"}]}]}},
		{"slug":"show-icon-title-3","name":"Show Icon Title 3","value":{"style":{},"triggers":[{"type":"hover","selector":".basket-title","stepsA":[{"wait":"450ms","display":"block","opacity":1,"transition":"opacity 450ms ease 0"}],"stepsB":[{"wait":"350ms","display":"block","opacity":0,"transition":"opacity 350ms ease 0"}]}]}},
		{"slug":"show-icon-title-4","name":"Show Icon Title 4","value":{"style":{},"triggers":[{"type":"hover","selector":".stores-title","stepsA":[{"wait":"450ms","display":"block","opacity":1,"transition":"opacity 450ms ease 0"}],"stepsB":[{"wait":"350ms","display":"block","opacity":0,"transition":"opacity 350ms ease 0"}]}]}},
		{"slug":"show-icon-title-5","name":"Show Icon Title 5","value":{"style":{},"triggers":[{"type":"hover","selector":".support-title","stepsA":[{"wait":"450ms","display":"block","opacity":1,"transition":"opacity 450ms ease 0"}],"stepsB":[{"wait":"350ms","display":"block","opacity":0,"transition":"opacity 350ms ease 0"}]}]}},
		{"slug":"sticky-nav-scroll-up","name":"Sticky Nav Scroll Up","value":{"style":{},"triggers":[{"type":"scroll","selector":".top-nav-div","stepsA":[{"opacity":1,"height":"32px","transition":"height 450ms ease 0, opacity 200 ease 0"}],"stepsB":[{"wait":"450ms","display":"flex","opacity":0,"height":"0px","transition":"opacity 450ms ease 0, height 450ms ease 0"}]}]}},
		{"slug":"show-account-dropdown","name":"Show Account Dropdown","value":{"style":{},"triggers":[{"type":"hover","selector":".account-dropdown-list","stepsA":[{"opacity":1,"transition":"opacity 450ms ease 0"}],"stepsB":[{"opacity":0,"transition":"opacity 450ms ease 0"}]}]}},
		{"slug":"basket-amount-show-regster-now","name":"Basket Amount Show Regster Now","value":{"style":{},"triggers":[{"type":"hover","selector":".register-now-link","stepsA":[{"opacity":1,"transition":"opacity 450ms ease 0"}],"stepsB":[{"opacity":0,"transition":"opacity 450ms ease 0"}]}]}},
		{"slug":"for-two-part-button-toggle","name":"For Two Part Button Toggle","value":{"style":{},"triggers":[{"type":"click","selector":".two-part-button-list","siblings":true,"preserve3d":true,"stepsA":[{"transition":"transform 750ms ease 0","x":"-100%","y":"0px","z":"0px"}],"stepsB":[]}]}},
		{"slug":"for-two-part-list-back-button","name":"For Two Part List Back Button","value":{"style":{},"triggers":[{"type":"click","selector":".two-part-button-list","preserve3d":true,"stepsA":[{"transition":"transform 750ms ease 0","x":"100%","y":"0px","z":"0px"}],"stepsB":[]}]}},
		{"slug":"price-section-open","name":"Price Section Open","value":{"style":{"opacity":1},"triggers":[{"type":"click","selector":".property-type-list-div-10","stepsA":[{"display":"none","transition":"opacity 450ms ease 0, height 450ms ease 0"}],"stepsB":[{"opacity":1,"transition":"opacity 250ms ease 0"},{"display":"block"}]}]}},
		{"slug":"price-section-open-2","name":"Price Section Open 2","value":{"style":{"opacity":1},"triggers":[{"type":"click","selector":".property-type-list-div-10","stepsA":[{"display":"block","transition":"opacity 450ms ease 0, height 450ms ease 0"}],"stepsB":[]},{"type":"click","selector":".label-section-title-2","stepsA":[{"display":"block"}],"stepsB":[]},{"type":"click","stepsA":[{"display":"none"}],"stepsB":[]}]}},
		{"slug":"price-section-close","name":"Price Section Close","value":{"style":{"opacity":1},"triggers":[{"type":"click","selector":".property-type-list-div-10","stepsA":[{"display":"none","transition":"opacity 450ms ease 0, height 450ms ease 0"}],"stepsB":[]},{"type":"click","stepsA":[{"display":"none"}],"stepsB":[]},{"type":"click","selector":".plus-sign","stepsA":[{"display":"block"}],"stepsB":[]}]}},
		{"slug":"brand-section-open","name":"Brand Section Open","value":{"style":{"opacity":1},"triggers":[{"type":"click","selector":".property-type-list-div-brand","stepsA":[{"display":"none","transition":"opacity 450ms ease 0, height 450ms ease 0"}],"stepsB":[{"display":"block"}]}]}},
		{"slug":"open-mobile-search","name":"Open Mobile Search","value":{"style":{},"triggers":[{"type":"click","selector":".search-column","stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}},
		{"slug":"grid-view-button","name":"Grid View Button","value":{"style":{},"triggers":[{"type":"hover","selector":".thumbnail-view-tooltip","stepsA":[{"display":"block","opacity":1,"transition":"opacity 200 ease 0"}],"stepsB":[{"display":"none"}]}]}},
		{"slug":"list-view-button","name":"List View Button","value":{"style":{},"triggers":[{"type":"hover","selector":".list-view-tooltip","stepsA":[{"display":"block"}],"stepsB":[{"display":"none"}]}]}}
	]);
	/* eslint-enable */
});
